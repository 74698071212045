import { isEmpty } from "./check";

export default {
	
	/**
	 * 设置配置
	 * @param name
	 * @param value
	 */
	set(name,value){
		uni.setStorageSync(name,value);
	},
	
	/**
	 * 设置json
	 * @param name
	 * @param value
	 */
	setJson(name,value){
		uni.setStorageSync(name,JSON.stringify(value));
	},
	
	/**
	 * 获取配置
	 * @param name
	 */
	get(name,def=null){
		let data = uni.getStorageSync(name);
		if(isEmpty(data)){
			return def;
		}
		
		return data;
	},
	
	/**
	 * 获取json配置
	 * @param name
	 */
	getJson(name,def=null){
		try{
			const content = uni.getStorageSync(name);
			if(isEmpty(content)){
				return def;
			}
			
			return JSON.parse(content);
		}catch(e){
			return def;
		}
	},
	
	/**
	 * 删除缓存
	 * @param name
	 */
	remove(name){
		uni.removeStorageSync(name);
		// uni.removeStorage({
		// 	key: name,
		// 	success: function (res) {
		// 		console.log('success removeStorage');
		// 	}
		// });
	},
	
	/**
	 * 清空缓存
	 */
	clear(){
		uni.clearStorageSync();
	}
};