import { deepMerge } from "./utils.js";
import { isString,isArray,isObject,isEmpty } from "./check.js";

export default {
	
	config: {},
	
	/**
	 * 设置配置
	 * @param name
	 * @param value
	 */
	set(name,value=""){
		if(isObject(name)){
			deepMerge(this.config,name);
			return true;
		}
		
		if(!isString(name)){
			return false;
		}
		
		let array = name.split(".");
		if(array.length <= 1){
			this.config[name] = value;
			return true;
		}
		
		let count = array.length;
		switch(count){
			case 2:
				this.config[array[0]][array[1]] = value;
				break;
			case 3:
				this.config[array[0]][array[1]][array[2]] = value;
				break;
			case 4:
				this.config[array[0]][array[1]][array[2]][array[3]] = value;
				break;
		}
		
		return true;
	},
	
	/**
	 * 读取配置
	 * @param string name
	 * @param mixed def
	 */
	get(name="",def=null){
		if(isEmpty(name)){
			return this.config;
		}
		
		let array = name.split(".");
		let count = array.length;
		let temp  = {};
		for(let i=0; i<count; i++){
			if(isEmpty(temp)){
				temp = !isEmpty(this.config[array[i]]) ? this.config[array[i]] : null;
			}else{
				temp = !isEmpty(temp[array[i]]) ? temp[array[i]] : null;
			}
		}
		
		return isEmpty(temp) ? def : temp;
	}
	
}
