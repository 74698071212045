import { defineStore } from 'pinia';

export const useTabbarStore = defineStore('tabbar',{
	
	state: () => {
		return { 
			type: "1",
			color: "#333333",
			selectedColor: "#1b43c4",
			borderStyle: "white",
			backgroundColor: "#ffffff",
			list: [] || uni.$storage.getJson("tabbar",[])
		};
	},
	
	actions: {
		
		setData(data){
			let array			 = [];
			for(let index in data.list){
				let obj = data.list[index];
				if(obj.status == 1){
					array.push({
						pagePath: obj.link,
						iconPath: obj.image,
						selectedIconPath: obj.selectedImage,
						text: obj.text
					});
				}
			}
			
			this.type 			 = data.type;
			this.color 			 = data.color;
			this.selectedColor 	 = data.selectedColor;
			this.borderStyle 	 = data.borderStyle;
			this.backgroundColor = data.backgroundColor;
			this.list 			 = array;
			data.list			 = array;
			uni.$storage.setJson("tabbar",data);
		},
		
		getData(){
			if(this.list.length <= 0){
				return uni.$storage.getJson("tabbar",[]);
			}
			
			return {
				type: this.type,
				color: this.color,
				selectedColor: this.selectedColor,
				borderStyle: this.borderStyle,
				backgroundColor: this.backgroundColor,
				list: this.list
			};
		}
	}
	
});