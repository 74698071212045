import { defineStore } from 'pinia';

export const useUserStore = defineStore('user',{
	
	state: () => {
		return {
			token: uni.$storage.get("token",null) || null,
			user: uni.$storage.get("user",null) || null
		};
	},
	getters: {
		isLogin(state){
			return !!state.token;
		}
	},
	actions: {
		async getUser() {
			if(this.token == null || this.token.length <= 0) return ;
			const {code,data} = await uni.$http.get("user/center");
			if(!code) return ;
			this.user = data;
			uni.$storage.set("user",data);
		},
		login(value){
			this.token = value;
			uni.$storage.set("token",value);
		},
		logout(){
			this.token = null;
			this.user  = null;
			uni.$storage.remove("user");
			uni.$storage.remove("token");
		}
	}
	
});