import { defineStore } from 'pinia';

/*
   import { useCounterStore } from '@/store/user'
   const counter = useCounterStore()
   
   // 自动补全！ ✨
   counter.$patch({ count: counter.count + 1 })
   // 或使用 action 代替
   counter.increment()
   
 */
export const useAppStore = defineStore('app',{
	
	state: () => {
		return {
			site: uni.$storage.getJson("site",null) || {
				name: "",
				status: 1,
				avatar: ""
			},
			theme: uni.$storage.getJson("theme","royal-blue")
		};
	},
	
	actions: {
		setData(value){
			this.site  = value.site;
			this.theme = value.theme;
			uni.$storage.setJson("site",value.site);
			uni.$storage.setJson("theme",value.theme);
		},
		getTheme(){
			return this.theme;
		},
		getApp(){
			return this.site;
		}
	}
	
});