import { isObject,isArray,isString,isEmpty,isNumber } from "./check.js";

/**
 * 生成全局唯一标识符
 * @param prefix 前缀
 * @param split  分割符
 * @param len    生成长度
 */
export const guid = (prefix="",split="",len=32)=>{
	let guid = "";
	for (let i = 1; i <= len; i++){
		let flag = Math.floor(Math.random() * 10);
		if(flag%2 == 0) {
			let n = Math.floor(flag*2.4) + 97;
			guid += String.fromCharCode(n);
		} else {
			guid += flag;
		}        
		
		if(!isEmpty(split) && ((i==8)||(i==12)||(i==16)||(i==20))){
			guid += split;
		}
	}
	
	return prefix + guid;  
}

/**
 * 堵塞时间
 * @param 堵塞时间 单位ms 毫秒
 * @returns 返回promise
 */
export const sleep = (value = 30)=>{
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve()
		}, value)
	})
}

/**
 * 去除左边字符串中空格
 * @param str 
 * @returns 
 */
 export const ltrim = (str)=>{
	return str.replace(/^\s*/, '');
}

/**
 * 去除右边字符串中空格
 * @param str 
 * @returns 
 */
export const rtrim = (str)=>{
	return str.replace(/(\s*$)/g, '');
}

/**
 * 去除前后字符串中空格
 * @param str
 * @returns 
 */
export const trim = (str)=>{
	return str.replace(/^\s+|\s+$/g, '');
}

/**
 * 取一个区间数
 * @param min 最小值
 * @param max 最大值
 */
export const random = (min, max)=>{
	if (min >= 0 && max > 0 && max >= min) {
		const gab = max - min + 1
		return Math.floor(Math.random() * gab + min)
	}
	return 0
}

/**
 * 深拷贝
 * @param source
 * @returns
 */
export const deepClone = (source)=>{
    if (!source && typeof source !== "object") {
        return source;
    }

    const targetObj = source.constructor === Array ? [] : {};
    Object.keys(source).forEach((keys) => {
        if (source[keys] && typeof source[keys] === "object") {
            (targetObj)[keys] = deepClone(source[keys]);
        } else {
            (targetObj)[keys] = source[keys];
        }
    });

    return targetObj;
}

/**
 * 深度合并
 * @param src
 * @param target
 * @returns
 */
export const deepMerge = (src={},target={})=>{
    let key;
    for (key in target) {
        src[key] = isObject(target[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key]);
    }
    return src;
}

export const unit = (value,suffix="")=>{
	return /(%|px|rpx|auto)$/.test(value) ? value : value + suffix;
}

/**
 * px转rpx
 * @param number|string value
 * @param number windowWidth
 */
export const px2rpx = (value,unit=false,windowWidth=750)=>{
	// if(isNumber(value)) {
	// 	return unit ? value + 'rpx' : value;
	// }
	
	if(isString(value) && value.substr(-1) == '%'){
		return value;
	}
	
	const screenWidth = getSystemInfo().width;
	let num = (windowWidth * Number.parseInt(value)) / screenWidth;
	return unit ? num + 'rpx' : num;
}

/**
 * rpx转px
 * @param number|string value
 * @param number windowWidth
 */
export const rpx2px = (value,unit=false,windowWidth=750)=>{
	if(isNumber(value)) {
		return unit ? value + 'px' : value;
	}
	
	if(value.substr(-1) == '%'){
		return value;
	}
	
	const screenWidth = getSystemInfo().width;
	let num = (screenWidth * Number.parseInt(value)) / windowWidth;
	return unit ? num + 'px' : num;
}

/**
 * 检查数据长度
 * @param mixed value
 * @return int
 */
export const size = (value)=>{
	if(isEmpty(value)){
		return 0;
	}
	
	if(isString(value) || isArray(value)){
		return value.length;
	}
	
	if(isObject(value)){
		return Object.keys(value).length
	}
	
	return 0;
}

/**
 * 同步获取系统信息
 * return object
 */
export const getSystemInfo = ()=>{
	const info = uni.getSystemInfoSync();
	return {
		width: info.windowWidth,     // 可使用窗口宽度
		height: info.windowHeight,   // 可使用窗口高度
		os: info.system,			 // 操作系统版本
		appid: info.appId,			 // 应用appid
		brand: info.brand			 // 手机品牌
	};
}

export default {
	guid,
	sleep,
	ltrim,
	rtrim,
	trim,
	random,
	deepClone,
	deepMerge,
	px2rpx,
	rpx2px,
	size,
	getSystemInfo
}