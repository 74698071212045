
export default {
	request: {
		baseUrl: "http://a3-cms.top:8888/", // 请求链接
		header: {
			'content-type': 'application/json'
		},
		timeout: 60000,
		dataType: "json",
		responseType: "text"
	},
	tabBarList: {
		defaultHome: '',
		list: []
	}
}
