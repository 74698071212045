import { getCurrentInstance } from "vue";
import { isString,isObject,isEmpty } from "./check.js";
import { trim } from "./utils.js";

/**
 * 查询节点信息的对象
 * @param selector 选择器
 * @param all (所有节点|单节点)
 */
export const getClientRect = (selector, all=false)=>{
	return new Promise(resolve => {
		let instance = getCurrentInstance();
		uni.createSelectorQuery().in(instance)[all ? 'selectAll' : 'select'](selector).boundingClientRect(rect=>{
			if (all && Array.isArray(rect) && rect.length) resolve(rect);
			if (!all && rect) resolve(rect);
		}).exec();
	});
}

/**
 * 样式转换
 * @param (object|string) data
 * @param (string) type
 */
export const addStyle = (data, type="object")=>{
	if(isString(data) && data.length <= 0){
		return {};
	}
	
	if(isEmpty(data)){
		return {};
	}
	
	if(type === "string"){
		if(!isObject(data)){
			return {};
		}
		
		let array = [];
		for(let i in data) {
			let key = i.replace(/([A-Z])/g, '-$1').toLowerCase();
			array.push(`${key}:${data[i]}`);
		}
		
		return trim(array.join(";"));
	}
	
	if(!isString(data)){
		return data;
	}
	
	let array  = trim(data).split(';');
	let style  = {};
	for (let i = 0; i < array.length; i++) {
		if (array[i]) {
			const item 			 = array[i].split(':');
			style[trim(item[0])] = trim(item[1]);
		}
	}
	
	return style;
}

export default {
	getClientRect,
	addStyle
}