import { isEmpty } from "./check";

/**
 * 跳转地址
 * @param value 跳转地址
 * @param timer 时间（单位秒）
 */
export const navigateTo = (value,timer=0)=>{
	if(isEmpty(value)){
		return ;
	}
	
	if(value.substr(0,1) != "/"){
		value = "/" + value;
	}
	
	const fn = ()=>{
		let list = uni.$config.get("tabBarList.list",[]);
		if(list.length > 0 && uni.$e.check.inArray(value.substring(1,value.length),list)){
			uni.switchTab({ url: value });
		}else{
			uni.navigateTo({ url: value });
		}
	}
	
	if(timer == 0){
		fn();
	}else{
		setTimeout(fn,timer * 1000);
	}
}

export const redirectTo = (value)=>{
	if(value.substr(0,1) != "/"){
		value = "/" + value;
	}
	
	uni.redirectTo({ url: value });
}

export const reLaunch = (value)=>{
	if(value.substr(0,1) != "/"){
		value = "/" + value;
	}
	
	uni.reLaunch({ url: value });
}

export const switchTab = (value)=>{
	if(value.substr(0,1) != "/"){
		value = "/" + value;
	}
	
	uni.switchTab({ url: value });
}

export const navigateBack = ()=>{
	uni.navigateBack();
}