/**
 * 显示消息提示框
 * @param content 内容
 * @param time    时间
 */
export const showToast = (content,time=3000)=>{
	uni.showToast({
		icon:'none',
		title: content,
		duration: time
	});
}

/**
 * 隐藏消息提示框
 */
export const hideToast = ()=>{
	uni.hideToast();
}

/**
 * 显示 loading 提示框
 * @param content 内容
 * @param mask    透明蒙层
 */
export const showLoading = (content="加载数据中...",mask=true)=>{
	uni.showLoading({
	    title: content,
		mask: mask
	});
}

/**
 * 隐藏 loading 提示框
 */
export const hideLoading = ()=>{
	uni.hideLoading();
}

/**
 * 确认框
 * @param content 内容
 * @param title   标题
 */
export const showModal = (content,title="提示")=>{
	return new Promise((resolve,reject)=>{
		uni.showModal({
			title: title,
			content: content,
			success: function (res){
				if(res.confirm) {
					resolve();
				}else if(res.cancel){
					// reject();
				}
			}
		});
	});
}

export default {
	showToast,
	hideToast,
	showLoading,
	hideLoading,
	showModal
}