import { isString } from "./check.js";

/**
 * 获取时间对象
 * @param string|number value
 * @return Date
 */
export const getDate = (value)=>{
	let date = null;
	if(!value){
		date = new Date();
	}else if (/^\d{10}$/.test(value.toString().trim())) {
		date = new Date(value * 1000)
	}else if (isString(value) && /^\d+$/.test(value.trim())) {
		date = new Date(Number(value))
	}else if (isString(value) && value.includes('-') && !value.includes('T')) {
		date = new Date(value.replace(/-/g, '/'))
	}else {
		date = new Date(value)
	}
	
	return date;
}

/**
 * 获取时间缀
 * @param string|number value
 * @return string
 */
export const getTime = (value)=>{
	let date = getDate(value);
	return date.getTime();
}

/**
 * 获取格式化时间
 * @param string|number value
 * @param string formatStr
 * @return string
 */
export const format = (value,formatStr='yyyy-mm-dd')=>{
	let date = getDate(value);
	let o = {
		'y': date.getFullYear().toString(), // 年
		'm': (date.getMonth() + 1).toString().padStart(2, '0'), // 月
		'd': date.getDate().toString().padStart(2, '0'), // 日
		'h': date.getHours().toString().padStart(2, '0'), // 时
		'M': date.getMinutes().toString().padStart(2, '0'), // 分
		's': date.getSeconds().toString().padStart(2, '0'), // 秒
		'S': date.getMilliseconds().toString(), // 毫秒
		'q': (Math.floor(date.getMonth() + 3) / 3) // 季度
	}
	
	for(let key in o) {
	  let [ret] = new RegExp(`${key}+`).exec(formatStr) || [];
	  if(ret) {
	    let beginIndex = key === 'y' && ret.length === 2 ? 2 : 0
	    formatStr = formatStr.replace(ret, o[key].slice(beginIndex))
	  }
	}
	
	return formatStr;
}

/**
 * 优化时间输出
 * @param string|number timestamp
 * @param string|Boolean format
 * @return string
 */
export const getFormatTime = (timestamp=null,format='yyyy-mm-dd')=>{
	if (timestamp == null) {
		timestamp = Number(new Date());
	}
	
	timestamp = parseInt(timestamp);
	if (timestamp.toString().length == 10) timestamp *= 1000;
	let timer = (new Date()).getTime() - timestamp;
	timer = parseInt(timer / 1000);
	
	switch (true) {
		case timer < 300:
			return '刚刚';
		case timer >= 300 && timer < 3600:
			return `${parseInt(timer / 60)}分钟前`;
		case timer >= 3600 && timer < 86400:
			return `${parseInt(timer / 3600)}小时前`;
		case timer >= 86400 && timer < 2592000:
			return `${parseInt(timer / 86400)}天前`;
		default:
			if (format === false) {
				if (timer >= 2592000 && timer < 365 * 86400) {
					return `${parseInt(timer / (86400 * 30))}个月前`;
				} else {
					return `${parseInt(timer / (86400 * 365))}年前`;
				}
			} else {
				return format(timestamp, format);
			}
	}
}

export default {
	getDate,
	getTime,
	format,
	getFormatTime
}