
const toString = Object.prototype.toString

/**
 * 检查数据类型
 * @param value 
 * @param type 
 * @return bool
 */
 export const is = (value,type)=>{
    return toString.call(value) === `[object ${type}]`
}

/**
 * 检查是否为undefined
 * @param value 
 * @return bool
 */
 export const isDef = (value)=>{
    return typeof value !== 'undefined'
}

/**
 * 检查数据类型是否为object
 * @param value 
 * @return bool
 */
export const isObject = (value)=>{
    return value !== null && is(value, 'Object')
}

/**
 * 检查数据类型是否为数字
 * @param value 
 * @return bool
 */
export const isNumber = (value)=>{
    return is(value, 'Number')
}

/**
 * 检查数据类型是否为字符串
 * @param value 
 * @return bool
 */
export const isString = (value)=>{
    return is(value, 'String')
}

/**
 * 检查数据类型是否为函数
 * @param value 
 * @return bool
 */
export const isFunction = (value)=>{
    return typeof value === 'function'
}

/**
 * 检查数据类型是否为布尔类型
 * @param mixed value 
 * @return bool
 */
export const isBoolean = (value)=>{
    return is(value, 'Boolean')
}

/**
 * 检查数据类型是否为数组
 * @param value 
 * @return bool
 */
export const isArray = (value)=>{
    return value && Array.isArray(value)
}

/**
 * 判断一个值是否在数组中
 * @param search 要搜索的值
 * @param array  被搜索的数组
 * @return bool
 */
export const inArray = (search,array)=>{
	let flag = false;
	for(let i in array){
		if(array[i]==search){
			flag = true;
			break;
		}
	}

	return flag;
}

/**
 * 检查数据是否为空
 * @param mixed value 
 * @return bool
 */
export const isEmpty = (value)=>{
    if(value == null || value == undefined){
        return true;
    }

    if (isArray(value) || isString(value)) {
        return value.length === 0
    }
    
    if (value instanceof Map || value instanceof Set) {
        return value.size === 0
    }

    if (isObject(value)) {
        return Object.keys(value).length === 0
    }
    
    return false
}

/**
 * 检查数据是否为Map
 * @param value 
 * @return bool
 */
export const isMap = (value)=>{
    return is(value, 'Map')
}

/**
 * 检查是否为苹果端
 * @return bool
 */
export const isIOS = ()=>{
	return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}

/**
 * 是否车牌号
 * @param string value
 * @return bool
 */
export const isCarNo = (value)=>{
	if (value.length === 7) {
		return /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/.test(value);
	}
	
	if (value.length === 8) {
		return /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/.test(value);
	}
	
	return false;
}

/**
 * 验证URL格式
 * @param string value
 * @return bool
 */
export const isUrl = (value)=>{
	return /^((https|http|ftp|rtsp|mms):\/\/)(([0-9a-zA-Z_!~*'().&=+$%-]+: )?[0-9a-zA-Z_!~*'().&=+$%-]+@)?(([0-9]{1,3}.){3}[0-9]{1,3}|([0-9a-zA-Z_!~*'()-]+.)*([0-9a-zA-Z][0-9a-zA-Z-]{0,61})?[0-9a-zA-Z].[a-zA-Z]{2,6})(:[0-9]{1,4})?((\/?)|(\/[0-9a-zA-Z_!~*'().;?:@&=+$,%#-]+)+\/?)$/.test(value);
}

/**
 * 验证手机
 * @param string|number value 
 * @returns bool
 */
export const isMobile = (value)=>{
    return /^[1][3,4,5,7,8,9][0-9]{9}$/.test(value);
}

/**
 * 验证电话号码
 * @param string|number value 
 * @returns bool
 */
export const isPhone = (value)=>{
    return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value);
}

/**
 * 验证邮箱
 * @param string value 
 * @returns bool
 */
export const isEmail = (value)=>{
    return /^[0-9a-z][a-z0-9._-]{1,}@[a-z0-9-]{1,}[a-z0-9].[a-z.]{1,}[a-z]$/.test(value);
}

/**
 * 检查是否为金额
 * @param string|number value 
 * @returns bool
 */
export const isAmount = (value)=>{
    return /^[0-9]+.?[0-9]*/.test(value);
}

/**
 * 身份证验证
 * @param string|number value
 * @return bool
 */
export const isIdCard = (value)=>{
	return /^\d{15}|\d{18}$/.test(value);
}

/**
 * 银行卡验证
 * @param string|number value
 * @return bool
 */
export const isBankCard = (value)=>{
	return /^([1-9]{1})(\d{14}|\d{15}|\d{16}|\d{18})$/.test(value);
}

/**
 * 中文
 * @param string value
 * @return bool
 */
export const isChinese = (value)=>{
	return /^[\u4e00-\u9fa5]+$/gi.test(value);
}

/**
 * 多行中文
 * @param string value
 * @return bool
 */
export const isChineseTextarea = (value)=>{
	return /^[\u4e00-\u9fa5\u0020\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]+$/gi.test(value);
}

/**
 * 验证一个值范围[min, max]
 * @param number value
 * @param array param
 * @return bool
 */
export const isRange = (value, param)=>{
    return value >= param[0] && value <= param[1]
}

/**
 * 验证一个长度范围[min, max]
 * @param number value
 * @param array param
 * @return bool
 */
export const isRangeLength = (value, param)=>{
    return value.length >= param[0] && value.length <= param[1]
}

/**
 * 是否视频格式
 * @param {Object} value
 */
export const isVideo = (value)=>{
    return /\.(mp4|mpg|mpeg|dat|asf|avi|rm|rmvb|mov|wmv|flv|mkv|m3u8)/i.test(value)
}

/** 是否图片格式
 * @param {Object} value
 */
export const isImage = (value)=>{
    const newValue = value.split('?')[0];
    return /\.(jpeg|jpg|gif|png|svg|webp|jfif|bmp|dpg)/i.test(newValue);
}

/**
 * 获取终端类型
 * @return object
 */
export const browser = ()=>{
	let obj = { type: "", name: "", isWechat: false, isMini: false, isApp: false };
	
	// #ifdef APP-PLUS
	let platform = uni.getSystemInfoSync().platform;
	obj.name = platform;
	obj.isApp = true;
	if(platform == "ios"){
		obj.type = "app-ios";
	}else if(platform == "android"){
		obj.type = "app-android";
	}else{
		obj.type = "app";
	}
	// #endif
	
	// #ifdef H5
	obj.type = "h5";
	obj.name = window.navigator.userAgent;
	obj.isWechat = window.navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1; // 检查是否在微信公众号打开
	// #endif
	
	// #ifdef MP
	obj.isMini = true;
	// #endif
	
	// #ifdef MP-WEIXIN
	obj.type = "mp-weixin";
	obj.name = "微信小程序";
	// #endif
	
	// #ifdef MP-ALIPAY
	obj.type = "mp-alipay";
	obj.name = "支付宝小程序";
	// #endif
	
	// #ifdef MP-BAIDU
	obj.type = "mp-baidu";
	obj.name = "百度小程序";
	// #endif
	
	// #ifdef MP-TOUTIAO
	obj.type = "mp-toutiao";
	obj.name = "字节跳动小程序";
	// #endif
	
	// #ifdef MP-QQ
	obj.type = "mp-qq";
	obj.name = "QQ小程序";
	// #endif
	
	// #ifdef MP-360
	obj.type = "mp-360";
	obj.name = "360小程序";
	// #endif
	
	return obj;
}

export default {
	is,
	isDef,
	isObject,
	isNumber,
	isString,
	isFunction,
	isBoolean,
	isArray,
	inArray,
	isEmpty,
	isMap,
	isIOS,
	isMobile,
	isPhone,
	isEmail,
	isAmount,
	isIdCard,
	isBankCard,
	isRange,
	isRangeLength,
	isChinese,
	isChineseTextarea,
	isUrl,
	isCarNo,
	browser
}