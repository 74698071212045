export default {
	request: {
		// #ifdef H5
		baseUrl: window.location.protocol + "//" + window.location.host+"/api",
		// #endif
		// #ifdef MP || APP-PLUS
		// baseUrl: "https://b2cv3.a3-mall.com/api",
		// #endif
		// baseUrl: "http://a3shop-git.top:8888/api", // 请求链接
		header: {
			'content-type': 'application/json'
		},
		timeout: 60000,
		dataType: "json",
		responseType: "text"
	}
}